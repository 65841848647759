import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';

interface UploadInvoiceFormValues {
  file: File;
  scac: string;
  customer: string;
}

export class InvoiceUploadForm extends NavCarrierFormGroup<UploadInvoiceFormValues> {
  constructor() {
    super(groupStructure({
      file: fieldStructure(null, {validators: [v.required, v.createValidator('csv', file => file ? /.csv$/.test(file.name) : true)]}),
      scac: fieldStructure('', {validators: [v.required]}),
      customer: fieldStructure('', {validators: [v.required]})
    }));
  }

  generateFormData(): FormData {
    const formData = new FormData();
    const values = this.value;
    const documentJSON: any = {
      ScacCode: values.scac,
      CustomerName: values.customer
    };

    formData.append('file', values.file, values.file.name);
    formData.append('DocumentJson', JSON.stringify(documentJSON));

    return formData;
  }
}
